:root {
  --black-color: #010000;
  --black-2: #333;
  --grey-color: #ddd;
  --grey-background: #faf7f7;
  --pink-color: #f0f;
  --pink2-color: #ea00c4;
  --electric-blue-1: #0ff;
  --violet-1: #7300ff;
  --violet-2: #5a12b3;
  --white-color: #fff;
  --font-size: 2rem;
  --width-size: 86%;
  --font-family: Montserrat, sans-serif;
}

html {
  font-family: Montserrat, sans-serif;
  font-size: 62.5%;
}

.connections-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 4rem;

  width: 90vw;
  height: auto;
}

.connections-container__recenter {
  width: 100%;
  height: auto;

  position: relative; /* graph-icon is positioned absolute to this, graph tooltip is positioned relative to this */
}

.graph-hero-container {
  width: 100%;
  height: auto;

  justify-content: center;

  /* gap: 1rem; */
}

.graph-container {
  width: 100%;
  height: 60rem;
  /* flex-grow: 1; */

  display: flex;
  justify-content: center;

  background-color: var(--white-color);
}

.graph-btns-container {
  margin-top: 1rem;

  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.graph-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #5c5c5c;
}

.icon {
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* GRAPH HIGHLIGHT INTERACTION */
.overlayed {
  opacity: 0.1;
  transition: opacity 0.3s ease;
}
.overlayed-links {
  opacity: 0.1;
  transition: opacity 0.3s ease;
}

.highlighted-node {
  fill: #f0f;
}

.highlighted-ConnectedNode {
  fill: #5c5c5c;
  opacity: 1;
}

.highlighted-link {
  stroke: #f0f;
}

.select-element {
  cursor: pointer;
}

.cursor {
  cursor: default;
  pointer-events: none;
}

.node-label {
  font-size: 10px;
}

.graph-text {
  display: flex;
  justify-content: flex-start;
}

/* GRAPH MODAL */
/*
.graph-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0%;
  left: 0%;

  background-color: rgba(220, 220, 220, 0.4);

  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

 .graph-modal-container {
  width: 90%;
  height: 90%;

  background-color: white;
  position: relative;
}

.graph-logo {
  width: 5%;
  position: absolute;
  top: 2%;
  right: 1%;
} */

/* TODO: delete btn-graph */
/* .btn-graph {
  display: flex;
  align-items: center;
  justify-content: center;

  border: var(--grey-background) 1px solid;
  background-color: var(--white-color);

  cursor: pointer;
} */

/* .btn-graph:hover,
.btn-graph:active {
  transition: ease-in, 0.3s;
  background-color: var(--grey-background);
} */

.graph-modal-btn--view {
  /* width: 8%; */
  /* height: 5%; */
  position: absolute;
  bottom: 2%;
  right: 8%;
}

.spinner {
  margin: 5rem auto;
  text-align: center;
}

.spinner svg {
  height: 6rem;
  width: 6rem;
  fill: #f0f;
  animation: rotate 2s infinite linear;
}

/* SHARE TOOLTIP */

.share-container {
  width: auto;
  /* overflow-y: auto; */
  border-radius: 8px;
  border: 1px solid var(--grey-color);
  padding: 8px 8px;
  gap: 8px;

  position: absolute;
  top: 0;
  left: 0;

  background-color: var(--grey-background);
  z-index: 1000;
}

/* Common styles for share elements */
.share-element {
  padding: 8px;
  border-radius: 8px;

  gap: 8px;
  align-items: center;
  justify-content: flex-start;

  text-decoration: none;

  color: var(--black-color);
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
  cursor: pointer;

  border: none;
  background-color: var(--grey-background);
}

.share-element:active,
.share-element:hover {
  background-color: var(--grey-color);
}

/* Specific styles for button */
.share-button__copy {
  font-family: Montserrat, sans-serif; /* Specificity if required */
}

.share-icon {
  fill: var(--black-2);
  width: 20px;
  height: 20px;
}

.icon-container {
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000;
}

.notification {
  position: fixed;
  bottom: 50px;
  right: 20px;
  background-color: var(--grey-color);
  color: var(--black-color);
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

  font-size: 1.5rem;

  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

/* GRAPH TOOLTIP */

.tooltip {
  position: absolute;
  width: 30rem;
  height: 20rem;
  overflow-y: scroll;
  scrollbar-gutter: stable;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2.5rem 2.5rem;
  z-index: 1000;
}

/* scrollbar */

.tooltip::-webkit-scrollbar {
  width: 8px;
}

.tooltip::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tooltip::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.tooltip::-webkit-scrollbar-thumb:hover {
  background: var(--pink-color);
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
}

.tooltip-content ul {
  list-style-position: outside; /* Align bullets within text area */
  padding-left: 1.2rem; /* Remove default padding */
}

.tooltip-content ul li::marker {
  font-size: 1.2rem; /* Match bullet size to the text */
  color: var(--black-color); /* Customize bullet color */
  padding-left: 0;
}

.tooltip-expand {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

#tooltip-link {
  font-size: 1.2rem;
}

#tooltip-link:hover,
#tooltip-link:focus,
#tooltip-link:active {
  font-size: 1.2rem;
}

.tldr-text {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  color: var(--black-color);
}

.hidden {
  display: none;
}
