:root {
  --black-color: #010000;
  --black-2: #333;
  --grey-color: #ddd;
  --grey-background: #faf7f7;
  --pink-color: #f0f;
  --pink2-color: #ea00c4;
  --electric-blue-1: #0ff;
  --violet-1: #7300ff;
  --violet-2: #5a12b3;
  --white-color: #fff;
  --font-size: 2rem;
  --width-size: 86%;
  --font-family: Montserrat, sans-serif;
}

html {
  font-family: Montserrat, sans-serif;
  font-size: 62.5%;
}

.connections-container {
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  width: 90vw;
  height: auto;
  display: flex;
}

.connections-container__recenter {
  width: 100%;
  height: auto;
  position: relative;
}

.graph-hero-container {
  justify-content: center;
  width: 100%;
  height: auto;
}

.graph-container {
  background-color: var(--white-color);
  justify-content: center;
  width: 100%;
  height: 60rem;
  display: flex;
}

.graph-btns-container {
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: auto;
  margin-top: 1rem;
  display: flex;
}

.graph-icon {
  cursor: pointer;
  color: #5c5c5c;
  position: absolute;
  top: 5px;
  right: 5px;
}

.icon {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.overlayed, .overlayed-links {
  opacity: .1;
  transition: opacity .3s;
}

.highlighted-node {
  fill: #f0f;
}

.highlighted-ConnectedNode {
  fill: #5c5c5c;
  opacity: 1;
}

.highlighted-link {
  stroke: #f0f;
}

.select-element {
  cursor: pointer;
}

.cursor {
  cursor: default;
  pointer-events: none;
}

.node-label {
  font-size: 10px;
}

.graph-text {
  justify-content: flex-start;
  display: flex;
}

.graph-modal-btn--view {
  position: absolute;
  bottom: 2%;
  right: 8%;
}

.spinner {
  text-align: center;
  margin: 5rem auto;
}

.spinner svg {
  fill: #f0f;
  width: 6rem;
  height: 6rem;
  animation: 2s linear infinite rotate;
}

.share-container {
  border: 1px solid var(--grey-color);
  background-color: var(--grey-background);
  z-index: 1000;
  border-radius: 8px;
  gap: 8px;
  width: auto;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.share-element {
  color: var(--black-color);
  cursor: pointer;
  background-color: var(--grey-background);
  border: none;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
}

.share-element:active, .share-element:hover {
  background-color: var(--grey-color);
}

.share-button__copy {
  font-family: Montserrat, sans-serif;
}

.share-icon {
  fill: var(--black-2);
  width: 20px;
  height: 20px;
}

.icon-container {
  z-index: 1000;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.notification {
  background-color: var(--grey-color);
  color: var(--black-color);
  z-index: 1000;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.5rem;
  transition: opacity .3s, transform .3s;
  position: fixed;
  bottom: 50px;
  right: 20px;
  transform: translateY(20px);
  box-shadow: 0 4px 6px #0003;
}

.tooltip {
  scrollbar-gutter: stable;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 30rem;
  height: 20rem;
  padding: 2.5rem;
  position: absolute;
  overflow-y: scroll;
}

.tooltip::-webkit-scrollbar {
  width: 8px;
}

.tooltip::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tooltip::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.tooltip::-webkit-scrollbar-thumb:hover {
  background: var(--pink-color);
}

.tooltip-content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  display: flex;
}

.tooltip-content ul {
  padding-left: 1.2rem;
  list-style-position: outside;
}

.tooltip-content ul li::marker {
  color: var(--black-color);
  padding-left: 0;
  font-size: 1.2rem;
}

.tooltip-expand {
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

#tooltip-link, #tooltip-link:hover, #tooltip-link:focus, #tooltip-link:active {
  font-size: 1.2rem;
}

.tldr-text {
  color: var(--black-color);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
}

.hidden {
  display: none;
}
/*# sourceMappingURL=index.5ce2a293.css.map */
